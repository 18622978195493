<script setup lang="ts">
import { VTooltip } from "vuetify/components/VTooltip";

interface Props {
  text?: string;
  // color?: string; // prop is no longer available
  disabled?: boolean;
  // nudgeBottom?: boolean; // prop is no longer available
  location?: Anchor; // previously `top` as prop
  closeDelay?: number;
  openDelay?: number;
}

const props = withDefaults(defineProps<Props>(), {
  text: undefined,
  disabled: undefined,
  closeDelay: undefined,
  openDelay: 300,
  location: "top center",
});
</script>

<template>
  <VTooltip
    v-bind="props"
    :eager="false"
  >
    <!-- new `props` slot data, previously called `attrs` -->
    <!-- previous `on` event is inside `props` now -->
    <template #activator="{ props: slotProps }">
      <slot
        name="activator"
        v-bind="{ props: slotProps }"
      />
    </template>
  </VTooltip>
</template>
