import type {
  InvoiceReceiptSchema,
  InvoiceProductSchema,
} from "@/pages/dashboard/index.vue";
export interface DraftInvoiceType {
  id: number;
  contactId?: string;
  receipts: InvoiceReceiptSchema[];
  products: InvoiceProductSchema[];
}

export const useDraftInvoiceStore = defineStore("draftInvoice", () => {
  // states
  const draftInvoices = ref<DraftInvoiceType[]>([]);

  // addDraftInvoice handler
  const addDraftInvoice = (draftInvoice: Omit<DraftInvoiceType, "id">) => {
    draftInvoices.value.push({
      ...draftInvoice,
      id: draftInvoices.value.length + 1,
    });
  };

  // removeDraftInvoice handler
  const removeDraftInvoice = (draftInvoiceId: number) => {
    if (draftInvoices.value.length === 0 || draftInvoiceId < 1) return;

    draftInvoices.value = draftInvoices.value.filter(
      (draftInvoice) => draftInvoice.id !== draftInvoiceId
    );
  };

  // updateDraftInvoice handler
  const updateDraftInvoice = (
    draftInvoiceId: number,
    draftInvoice: Omit<DraftInvoiceType, "id">
  ) => {
    const draftInvoiceIndex = draftInvoices.value.findIndex(
      (draftInvoice) => draftInvoice.id === draftInvoiceId
    );

    if (draftInvoiceIndex === -1) return;

    draftInvoices.value[draftInvoiceIndex] = {
      ...draftInvoices.value[draftInvoiceIndex],
      ...draftInvoice,
    };
  };

  // getDraftInvoice handler
  const getDraftInvoice = (draftInvoiceId: number) => {
    return draftInvoices.value.find(
      (draftInvoice) => draftInvoice.id === draftInvoiceId
    );
  };

  return {
    // getters
    draftInvoices,

    // setters
    addDraftInvoice,
    removeDraftInvoice,
    updateDraftInvoice,
    getDraftInvoice,
  };
});
